<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "nǐ mén",
              hanzi: "你们",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "wǒ mén",
              hanzi: "我们",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "fàndiàn",
              hanzi: "饭店",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "kǎoyā",
              hanzi: "烤鸭",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zhīshídiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              
             
              {
                pinyin: "rén chēng dài cí",
                hanzi: "人称代词",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
               {
                pinyin: "dē",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
               {
                pinyin: "fù shù xíng shì",
                hanzi: "复数形式",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "Wǒ",
                hanzi: "我",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "mén",
                hanzi: "们",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "chī",
                hanzi: "吃",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "běi jīng",
                hanzi: "北京",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
               {
                pinyin: "kǎoyā",
                hanzi: "烤鸭",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 2,
                index: 6,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "xún wèn",
              hanzi: "询问",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "jí",
              hanzi: "及",
              group: 2,
              index: 3,
            },
            {
              pinyin: "biǎo dá",
              hanzi: "表达",
              group: 2,
              index: 4,
            },
             {
              pinyin: "qù xiàng",
              hanzi: "去向",
              group: 2,
              index: 4,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>