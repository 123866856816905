<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="7"/>
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Wǒmen qù Běijīng fàndiàn (shàng)",
        hanzi: "我们去北京饭店 (上)",
        english: "Let's go to Beijing Hotel (I)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-51-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>