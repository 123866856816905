<template>
  <div class="game-container">
    <FindElementGame :bgImg="bgImg" :imgList="imgList" :lessonNo="51" :titleInfo="titleInfo"/>
  </div>
</template>

<script>
import FindElementGame from "@/components/Course/GamePage/FindElementGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/background.svg"),
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/1-rice.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/2-noodles.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/3-cake.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/4-apple.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/5-water.svg"),
        },
         {
          id: 6,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-51/6-milk.svg"),
        },
      ],
      titleInfo:{
        pinyin:"Nǐ hǎo! Nǐ chī shénme?",
        hanzi:"你好! 你吃什么?"
      }
    };
  },
  components: {
    FindElementGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>