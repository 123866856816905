<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :titleInfo="titleInfo"
      :lessonNo="51"
      :isAllScreen="true"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      clickIdList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-51/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-51/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-51/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-51/menu-4.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-51/menu-5.svg"),
          isCircle: true,
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-51/image-1.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-1-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-1-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-51/image-2.svg"),
          singlePageList: [
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-2-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-2-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-51/image-3.svg"),
          singlePageList: [
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-3-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-3-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 4,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-51/image-4.svg"),
          singlePageList: [
            {
              id: 7,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-4-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 8,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-4-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 5,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-51/image-5.svg"),
          singlePageList: [
            {
              id: 9,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-5-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 10,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-5-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-51/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
      ],
      titleInfo: {
        pinyin: "wǒmen/nǐmen",
        hanzi: "我们/你们",
      },
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>
