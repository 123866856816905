var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("OveturnPuzzleGame", {
        attrs: {
          bgImg: _vm.bgImg,
          imgList: _vm.imgList,
          puzzleBgImg: _vm.puzzleBgImg,
          mapList: _vm.mapList,
          correctIdLisdt: [1, 5, 6, 7],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }