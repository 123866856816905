<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "nǐmen",
          hanzi: "你们",
          english: "you (plural)",
          image: require(`@/assets/img/12-Vocabulary/lesson-51-nimen-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "wǒmen",
          hanzi: "我们",
          english: "we",
          image: require(`@/assets/img/12-Vocabulary/lesson-51-women-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "fàndiàn",
          hanzi: "饭店",
          english: "restaurant",
          image: require(`@/assets/img/12-Vocabulary/lesson-51-fandian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "kǎoyā",
          hanzi: "烤鸭",
          english: "roast duck",
          image: require(`@/assets/img/12-Vocabulary/lesson-51-kaoya-bubble.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
